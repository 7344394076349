import React from "react";
import ReactDOM from "react-dom";
import {TestimonialListItem} from "./TestimonialListItem";

const jQuery = window.jQuery, bootbox = window.bootbox;

class TestimonialList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            maxKey: -1,
            activeItem: 0
        }
    }

    add = () => {
        let newItems = this.state.items.slice();
        let count = 0;
        for (let item of newItems) {
            if (item.testimonialText === '' || item.testimonialAuthor === '') {
                count++;
            }
        }
        if (count > 0) {
            bootbox.alert('Please complete the testimonial form then add new');
        }
        else {
            newItems.push({
                testimonialText: '',
                testimonialAuthor: '',
                key: this.state.maxKey + 1
            });
            this.setState({maxKey: this.state.maxKey + 1});
            this.setState({items: newItems});
            this.setState({needRoll: true});

        }
    };

    componentWillMount = () => {
        if (this.props.items && this.props.items.length) {
            let newItems = this.props.items.map(function (item, i) {
                item.key = i;
                return item;
            });

            this.setState({
                maxKey: newItems.length - 1,
                items: newItems
            });

        }

        this.timmerHandle = setInterval(function () {
            this.setState({activeItem: this.state.activeItem + 1});
            if ((this.state.activeItem + 1) > this.state.items.length) {
                this.setState({activeItem: 0});
            }
        }.bind(this), 4000);
    };

    componentDidUpdate = (prevProps, prevState) => {

        if (this.state.needRoll) {
            let node = ReactDOM.findDOMNode(this.refs.bottomTes);
            if (node != null) {
                let heightTes = window.pageYOffset + node.getBoundingClientRect().top;
                jQuery("html, body").animate({scrollTop: heightTes}, 600);
            }
        }
    };

    itemRemove = (itemToRemove) => {
        let newItems = this.state.items.filter(function (item, i) {
            return !(itemToRemove.key === item.key);
        });

        this.setState({items: newItems});
        this.setState({needRoll: false});
    };

    itemChange = (itemToChange) => {
        let newItems = this.state.items.map(function (item, i) {
            if (item.key === itemToChange.key) {
                return itemToChange;
            }
            return item;
        });

        this.setState({items: newItems});
        this.setState({needRoll: false});
    };

    getItems = () => {
        return this.state.items;
    };

    componentWillReceiveProps = (nextProps) => {
        if (this.props.editMode !== nextProps.editMode) {
            let newItems = this.state.items.filter(function (item, index) {
                // remove any items that have no values
                return !(item.testimonialAuthor === '' || item.testimonialText === '');
            });
            this.setState({items: newItems});
            this.setState({needRoll: false});
            if (nextProps.editMode) {
                clearInterval(this.timmerHandle);

            }
            else {
                this.timmerHandle = setInterval(function () {
                    this.setState({activeItem: this.state.activeItem + 1});
                    if ((this.state.activeItem + 1) > this.state.items.length) {
                        this.setState({activeItem: 0});
                    }
                }.bind(this), 4000);
            }
        }
    };

    render() {

        let me = this;
        let buttons = null;

        if (this.props.editMode) {
            buttons = (
                <i onClick={this.add} className="fa fa-plus pull-right" style={{cursor: 'pointer'}}/>
            );
        }
        else {
            if (this.state.items.length === 0) {
                // no testimonials so don't display this section
                return null;
            }
        }

        let content = '';
        if (this.state.items.length > 0) {

            let ListItems = this.state.items.map(function (item, index) {
                let listSize = me.state.items.length;

                let is_Active = this.state.activeItem === index;

                return (
                    <TestimonialListItem
                        key={item.key}
                        defaults={item}
                        editMode={me.props.editMode}
                        onRemove={me.itemRemove}
                        onChange={me.itemChange}
                        testimonialText={item.testimonialText}
                        testimonialAuthor={item.testimonialAuthor}
                        onAdd={me.add}
                        isActive={is_Active}
                        isLastItem={listSize - 1 === index}
                    />
                );
            }.bind(this));

            content = (
                <ul className={this.props.editMode ? '' : 'testimonial-list'}>
                    {ListItems}
                    <li ref="bottomTes"/>
                </ul>
            );
        }
        else {
            content = (
                <div className="nothingToShow">
                    No Testimonial
                    {this.props.editMode ?
                        <a onClick={this.add} style={{cursor: 'pointer', display: 'block', margin: '0 auto'}}>Add
                            Testimonial</a>
                        :
                        null
                    }
                </div>
            );
        }
        let show;
        if (me.props.editMode) {
            show = (
                <div className="panel panel-default testimonials-area">
                    <div className="panel-heading"> Testimonials
                        {buttons}
                    </div>
                    <div className="panel-body">
                        {content}
                    </div>
                </div>
            );
        }
        else {
            show = (
                <div className="UserTestimonials">
                    <h3>Testimonials</h3>
                    <div>{content}</div>
                </div>
            );
        }

        return (
            <div>
                {show}
            </div>
        )
    }
} // TestimonialList


export {TestimonialList}
