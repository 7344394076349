import React from "react";
import {FontSelector} from "./FontSelector";
import Slider from "rc-slider";
import {ChromePicker} from "react-color";
import {Modal} from "react-overlays";

const jQuery = window.jQuery, bootbox = window.bootbox;


const styles = {
    modal: {
        position: 'fixed',
        zIndex: 1040,
        top: 0, bottom: 0, left: 0, right: 0
    },
    backdrop: {
        position: 'fixed',
        top: 0, bottom: 0, left: 0, right: 0,
        zIndex: 'auto',
        backgroundColor: '#000',
        opacity: 0.5
    },
    dialog: {
        position: 'absolute',
        top: 0, bottom: 0, left: 0, right: 0,
        border: '1px solid #e5e5e5',
        backgroundColor: 'white',
        boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflowY: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100%',
        maxWidth: '1024px'
    },
    editButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '26px',
        height: '26px',
        padding: 0,
        margin: 0,
        textAlign: 'center',
        zIndex: 1040
    },
    editIcon: {
        fontSize: '15px'
    },
};

class CustomButtonEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            label: 'Continue',
            url: '',
            countSeconds: 0,
            className: 'btn btn-default',
            newTab: true,
            fonts: {},
            style: {
                borderRadius: 0,
                backgroundColor: '#0000BB',
                color: '#FFFFFF',
                fontFamily: ''
            },
            target: null
        };

        this.sizeMap = ['btn-xs', 'btn-sm', 'btn-md', 'btn-lg'];
        this.editableStyles = [
            'cornerRadius',
            'backgroundColor',
            'color',
            'fontFamily'
        ];
    }

    filterStyle = (style) => {
        let i, newStyle = {};
        for (i in this.editableStyles) {
            if (style.hasOwnProperty(this.editableStyles[i])) {
                newStyle[this.editableStyles[i]] = style[this.editableStyles[i]];
            }
        }
        return newStyle;
    };

    handleSimpleChange = (event, callback) => {
        let fieldDetails = {};
        fieldDetails[event.target.name] = event.target.value;
        this.setState(fieldDetails, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    };

    toggle = () => {
        this.setState({showModal: !this.showModal});
    }; // toggle()

    close = () => {
        this.setState({showModal: false}, () => {
            if (typeof this.props.onClose === 'function') {
                this.props.onClose();
            }
        });
    }; // close()

    open = () => {
        this.setState({showModal: true});
    }; // open()

    componentWillMount = () => {
        let newState = {};
        if (this.props.targetButton) {
            newState.target = this.props.targetButton;
        }
        if (this.props.buttonDetails) {
            newState = this.props.buttonDetails;
        }
        if (this.props.show) {
            newState.showModal = this.props.show;
        }

        this.setState(newState);
    };

    componentWillReceiveProps = (nextProps) => {
        if (this.props.show !== nextProps.show) {
            this.setState({showModal: nextProps.show});
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (typeof this.props.onChange === 'function' && JSON.stringify(prevState) !== JSON.stringify(this.state)) {
            this.props.onChange(this.state);
        }
    };

    setTargetStyle = (target) => {
        return jQuery(target).css(this.state.style);
    };

    getTargetStyle = (target) => {
        return this.filterStyle(jQuery(target).css());
    };

    onButtonSizeSliderChange = (value) => {
        let className = this.state.className;
        let i;
        for (i in this.sizeMap) {
            className = className.replace(new RegExp(this.sizeMap[i], 'ig'), '');
        }
        className = className.replace(/[\s]{2,}/g, '');
        className += ' ' + this.sizeMap[value];

        this.setState({size: value, className: className});
    };

    onBorderRadiusSliderChange = (value) => {
        let style = jQuery.extend({}, this.state.style);
        style.borderRadius = value;
        this.setState({style: style});
    };

    onButtonBackgroundColorChange = (value) => {
        let style = jQuery.extend({}, this.state.style);
        style.backgroundColor = value.hex;
        this.setState({style: style});
    };

    onButtonTextColorChange = (value) => {
        let style = jQuery.extend({}, this.state.style);
        style.color = value.hex;
        this.setState({style: style});
    };

    handleFontSelected = (font) => {
        let style = jQuery.extend({}, this.state.style);
        style.fontFamily = font.fontFamily;

        let fonts = jQuery.extend({}, this.state.fonts);
        if (typeof font.source !== 'undefined') {
            // there is only one font possible for a button but there could be more in other custom components
            fonts[font.source] = [font.fontFamily];
        }
        this.setState({style: style, fonts: fonts});
    };

    /**
     * The parts of the state that we actually want saved.
     */

    getSaveState = () => {
        let saveState = jQuery.extend({}, this.state);
        if (saveState.target) delete(saveState.target);
        if (saveState.showModal) delete(saveState.showModal);
        return saveState;
    };

    confirm = () => {
        if (typeof this.props.onConfirm === 'function') {
            if (isNaN(this.state.countSeconds)) {
                bootbox.alert("Please input a number!");
            }
            else {
                this.props.onConfirm(this.getSaveState());
                this.close();
            }
        }
    };

    generatePreview = () => {
        return (
            <div className="preview" style={{maxHeight: 600, width: '60em', overflow: 'hidden'}}>
                <a href={this.state.url} className={this.state.className} style={this.state.style} role="button"
                   onClick={(event) => {
                       event.preventDefault()
                   }} target={this.state.newTab ? '_blank' : '_self'}>{this.state.label}</a>
            </div>
        );
    };

    render() {
        let preview = this.generatePreview();

        let editor = (
            <div>
                <h3>Preview</h3>
                <div className="well">
                    {preview}
                </div>
                <div className="form-group col-sm-6">
                    <label className="control-label" htmlFor="buttonLabel">Button Label</label>
                    <input placeholder="Button Label"
                           id="buttonLabel"
                           name="label"
                           type="text"
                           className="form-control"
                           value={this.state.label}
                           onChange={this.handleSimpleChange}/>
                </div>
                <div className="form-group col-sm-6">
                    <label className="control-label" htmlFor="buttonUrl">Button Url</label>
                    <input
                        placeholder="https://"
                        id="buttonUrl"
                        name="url"
                        type="text"
                        className="form-control"
                        value={this.state.url}
                        onChange={this.handleSimpleChange}/>
                </div>
                <div className="form-group col-sm-6">
                    <label className="control-label" htmlFor="buttonFont">Font Family</label>
                    {/*<FontSelectorTest onSelect={this.handleFontSelected} fontFamily={this.state.style.fontFamily}/>*/}
                    <FontSelector onSelect={this.handleFontSelected} fontFamily={this.state.style.fontFamily}/>
                </div>
                <div className="form-group col-sm-6">
                    <label className="control-label" htmlFor="buttonLabel">Open new tab?</label>
                    <div className="radio">
                        <label><input type="radio" value={true} name="newTab" checked={this.state.newTab}
                                      onChange={() => {
                                          this.setState({newTab: true})
                                      }}/> Yes</label>
                        <label style={{marginLeft: '2em'}}><input type="radio" value={false} name="newTab"
                                                                  checked={!this.state.newTab} onChange={() => {
                            this.setState({newTab: false})
                        }}/> No</label>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        <label>Corner Radius</label>
                        <Slider
                            value={this.state.style.borderRadius}
                            onChange={this.onBorderRadiusSliderChange}
                            min={0} max={30}
                        />
                    </div>
                    <div className="form-group">
                        <label>Size</label>
                        <Slider
                            value={this.state.size}
                            onChange={this.onButtonSizeSliderChange}
                            min={0} max={3}
                            onAfterChange={(value) => {
                            }}
                        />
                    </div>
                </div>
                <div className="form-group col-sm-4">
                    <label>Text Color</label>
                    <ChromePicker
                        color={this.state.style.color}
                        onChangeComplete={this.onButtonTextColorChange}
                    />
                </div>
                <div className="form-group col-sm-4">
                    <label>Background Color</label>
                    <ChromePicker
                        color={this.state.style.backgroundColor}
                        onChangeComplete={this.onButtonBackgroundColorChange}
                    />
                </div>
                {/*
				<div className="form-group col-sm-6">
					<label className="control-label">Start after X seconds (Please input a number)</label>
					<input placeholder="0"
					       type="text"
					       name="countSeconds"
					       className="form-control"
					       value={this.state.countSeconds}
					       onChange={this.handleSimpleChange}/>
				</div>
*/}
            </div>
        );

        return (
            <Modal
                aria-labelledby='modal-label'
                style={styles.modal}
                backdropStyle={styles.backdrop}
                show={this.state.showModal}
                onHide={this.close}
                enforceFocus={false}
            >
                <div style={styles.dialog}>
                    <div className="btn-group" role="group" style={{
                        position: 'fixed',
                        top: 0,
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 1100
                    }}>
                        <button type="button" className="btn btn-lg btn-danger btn-top-menu"
                                onClick={() => this.close()}><i className="fa fa-times" aria-hidden="true"/> Close
                        </button>
                        <button type="button" className="btn btn-lg btn-success btn-top-menu" onClick={() => {
                            this.confirm();
                        }}><i className="fa fa-check" aria-hidden="true"/> Confirm
                        </button>
                    </div>
                    {editor}
                </div>
            </Modal>
        );
    }
} // CustomButtonEditor

export {CustomButtonEditor}