import React from "react";
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class CustomBlock extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.initialDraftContent) {
            this.state = {
                editorState: EditorState.createWithContent(convertFromRaw(this.props.initialDraftContent)),
            };
        }
        else {
            this.state = {
                editorState: EditorState.createEmpty(),
            };
        }
    } // constructor()

    componentDidMount = () => {
        if (this.props.onMount) {
            this.props.onMount(this);
        }
    };

    setEditorState = (content) => {
        //console.log(content);
        this.setState({editorState: EditorState.createWithContent(convertFromRaw(content))});
    };

    getHtml = () => {
        if (this.state.editorState) {
            let html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
            let modifiedHtml = html;
            while (html.indexOf('<img') !== -1) {
                let img = html.slice(html.indexOf('<img'), html.indexOf('/>', html.indexOf('<img')) + 2);
                if (img.indexOf('float:none') !== -1) {
                    modifiedHtml = modifiedHtml.replace(img, '<div style="display:flex;justify-content:center;">' + img + '</div>');
                }
                html = html.replace(img, '');
            }
            return modifiedHtml;
        }
        return '';
    };

    getRaw = () => {
        if (this.state.editorState) {
            return convertToRaw(this.state.editorState.getCurrentContent());
        }
        return {};
    };

    onEditorStateChange = (editorState) => {
        //console.log(convertToRaw(editorState.getCurrentContent()));
        //console.log('ssh');
        //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        this.setState({
            editorState: editorState,
            editorHtml: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        });
    };
    onBlur = (event) => {
        // now what?
    };  // onBlur()

    onFocus = (event) => {
        // now what?
    };  // onFocus()

    render() {
        //console.log('render');
        if (!this.props.editMode && !this.state.editorState.getCurrentContent().hasText()) {
            return null;
        }
        // editable
        const {editorState} = this.state;
        let editor;
        if (this.props.editMode) {
            editor = (
                <Editor
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    readOnly={!this.props.editMode}
                    toolbarClassName="home-toolbar"
                    wrapperClassName="home-wrapper"
                    editorClassName="home-editor"
                    placeholder="This space is editable!"
                    toolbarOnFocus
                    spellCheck
                />
            );
        }
        else {
            editor = (<div dangerouslySetInnerHTML={{__html: this.getHtml()}}/>);
        }

        return (
            <div className={this.props.className}>
                {editor}
                <div className="clearfix"/>
            </div>
        );
    } // render()
} // CustomBlock

export {CustomBlock}