import React, {Component} from 'react';
import {Webinar} from './components/Webinar';
import './App.css';


class App extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            id: document.getElementById('tcp-webinar').getAttribute('data-id'),
            live: (document.getElementById('tcp-webinar').getAttribute('data-live') === 'true' || document.getElementById('tcp-webinar').getAttribute('data-live') === '1'),
            path: document.getElementById('tcp-webinar').getAttribute('data-path'),
        });
    }

    render() {
        return (
    <
        Webinar
        live = {this.state.live
    }
        id = {this.state.id
    }
        path = {this.state.path
    }
        />
    )
    }
}

export default App;
