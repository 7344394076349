import React from "react";

class SocialListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            name: '',
            key: 0,
            id: 0
        }
    }

    handleURLChange = (e) => {
        let url = e.target.value;
        if (url.length > 8 && !/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        this.setState({
            url: url
        }, () => {
            this.props.onChange(this.state);
        });
    };

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        }, () => {
            this.props.onChange(this.state);
        });
    };

    componentWillMount = () => {
        if (this.props.defaults) {
            this.setState(this.props.defaults);
        }
    };

    render() {
        let content = null;
        let addButton = null;
        if (this.props.isLastItem) {
            addButton = (
                <button onClick={() => {
                    this.props.onAdd()
                }} className="btn btn-primary"><i className="fa fa-plus"/> Add
                </button>
            );
        }
        if (this.props.editMode) {
            content = (<div className="well well-sm">
                <div className="form-group">
                    <label className="control-label">Social Media Network </label>
                    <select value={this.state.name} className="form-control" onChange={this.handleNameChange}>
                        <option value="">(choose your network)</option>
                        <option value="facebook">Facebook</option>
                        <option value="youtube">YouTube</option>
                        <option value="twitter">Twitter</option>
                        <option value="snapchat">SnapChat</option>
                        <option value="pinterest">Pinterest</option>
                        <option value="linkedin">LinkedIn</option>
                        <option value="google-plus">Google+</option>
                        <option value="reddit">reddit</option>
                        <option value="instagram">Instagram</option>
                    </select>
                </div>
                <div className="form-group">
                    <label className="control-label">Link </label>
                    <input type="text" className="form-control" value={this.state.url} onChange={this.handleURLChange}
                           placeholder="http:\\"/>
                </div>
                <div className="form-group">
                    <button onClick={() => {
                        this.props.onRemove(this.state)
                    }} className="btn btn-warning" style={{marginRight: 10}}><i className="fa fa-remove"/> Remove
                    </button>
                    {addButton}
                </div>
                <div className="clearfix"/>
            </div>);
        }
        else {
            let socialIcon;
            if (this.state.name === 'instagram') {
                socialIcon = 'fa fa-' + this.state.name + ' fa-3x fa-fw';
            }
            else {
                socialIcon = 'fa fa-' + this.state.name + '-square fa-3x fa-fw';
            }
            content = (<span className="text-left"><a href={this.state.url} target="_blank"><i className={socialIcon}/></a></span>);
        }

        return (
            <li style={{padding: '5px'}}>
                {content}
            </li>
        )
    }
} // SocialListItem

export {SocialListItem}