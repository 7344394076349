import React from "react";
import BasicOverlay from "../BasicOverlay";


const styles = {
	dialog: {
		position:        'absolute',
		top:             0, bottom: 0, left: 0, right: 0,
		marginLeft:      'auto',
		marginRight:     'auto',
		width:           '100%',
		minWidth:        '300px',
		maxWidth:        '600px',
		border:          '1px solid #e5e5e5',
		backgroundColor: 'white',
		boxShadow:       '0 5px 15px rgba(0,0,0,.5)',
		padding:         10,
		//overflowY: 'auto',
		verticalAlign: 'middle'
	},
	body:   {
		marginTop: '1em'
	}
};


class ConfirmDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: true
		};
	} // constructor()

	render() {

		return (
			<BasicOverlay
				{...this.props.modal}
				dialogStyles={styles.dialog}
				showModal={this.state.showModal}
				onToggle={() => {
					if(this.state.showModal !== false){
						this.setState({showModal: false}, () => {
							this.close();
						});
					}
				}}
				showClose={false}
			>
				<div className="tf-dialog">
					<div className="tf-dialog-header">
						<img className="float-left tf-dialog-logo" src="https://cdn.theconversionpros.com/assets/images/logo-short.png" style={{maxHeight: '3em'}}
						     title="The Conversion Pros, Inc." alt={'The Conversion Pros, Inc.'}/>
						<button onClick={this.cancel} className="float-right" style={{background: 'none', border: 'none', cursor: 'pointer'}}>
							<i className="fa fa-times fa-2x" aria-hidden="true" title="Close"/>
						</button>
						<div className='text-center p-2'><label>{this.props.title}</label></div>
					</div>
					<div className="clearfix"/>
					<div className="tf-dialog-body" style={styles.body}>
						<div className="tf-dialog-body-middle">
							{this.props.children}
						</div>
					</div>
					<div className="tf-dialog-footer justify-content-between" role="toolbar">
						<button onClick={this.yes} className="btn btn-success  float-right" style={{paddingLeft: 5}}>{this.props.labels.ok || (<><i className="fa fa-check"/> Yes</>)}</button>
						<button onClick={this.cancel} className="btn btn-danger float-right" style={{marginRight: 10}}>{this.props.labels.cancel || <><i className="fa fa-ban"/> Cancel</>}</button>
					</div>
				</div>
			</BasicOverlay>
		);
	}

	cancel = (e) => {
		if(typeof this.props.onCancel === 'function'){
			this.props.onCancel(e);
		}
		this.close();
	};

	yes = (e) => {
		if(typeof this.props.onYes === 'function'){
			this.props.onYes(e);
		}
		this.close();
	};

	open = () => {
		if(this.state.showModal === true){
			// already set
			return;
		}
		this.setState({showModal: true}, () => {
			if(typeof this.props.onOpen === 'function'){
				this.props.onOpen();
			}
		});

	};

	close = () => {
		if(this.state.showModal === false){
			// already set
			return;
		}
		this.setState({showModal: false}, () => {
			if(typeof this.props.onClose !== 'undefined'){
				this.props.onClose();
			}
		});
	};

} // ConfirmDialog

export default ConfirmDialog