import React from "react";
import ContentEditable from "react-contenteditable";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'rc-slider/assets/index.css';
import {TestimonialList} from './TestimonialList.jsx';
import {SocialList} from './SocialList.jsx';
import {WebinarList} from './WebinarList.jsx';
import {CustomBlock} from './CustomBlock.jsx';
import {VideoPanel} from './VideoPanel.jsx';
import {BackgroundButton} from './BackgroundButton.jsx';

import {ContactUsRequest} from './ContactUsRequest.jsx';

import update from 'immutability-helper';
// import tf from '../tf.js';
import tf from './shared/tf.jsx';


const $ = window.$, jQuery = window.jQuery, bootbox = window.bootbox, WebFont = window.WebFont, moment = window.moment,
    history = window.history;


let mergeFonts = (fonts1, fonts2) => {
    let fonts = {...fonts1};
    for (let i in fonts2) {
        if (typeof fonts[i] === 'undefined') {
            fonts[i] = update(fonts[i], {$set: fonts2[i]});
        }
        else {
            for (let j in fonts2[i]) {
                if (fonts[i].indexOf(fonts2[i][j]) === -1) {
                    fonts[i].push(fonts2[i][j]);
                }
                else {
                    // already exists
                }
            } // for
        }
    } // for
    return fonts;
};

class Webinar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            playingWebinar: null,
            webinarId: 0,
            title: 'Add Headline Here',
            uniqueName: '',
            webinars: [],
            socialLinks: [],
            testimonials: [],
            customBlocks: [],
            contents: [],
            customOne: {},
            customTwo: {},
            style: [],

        };

        this.customBlocks = [];

        this.apiUrl = '/w-editor/rpc/';
        if (this.props.live) {
            this.apiUrl = '/w/rpc/';
        }

    }

    toggleEditModes = (callback) => {
        this.setState({
            editMode: !this.state.editMode,
        });
        if (this.webinarList) {
            let webinars = this.webinarList.getItems();
            webinars = this.sort(webinars);

            this.setState({webinars: webinars}, () => {
                this.forceUpdate();
                if (typeof callback === 'function') {
                    callback();
                }
            });
        }
    };

    getFonts = () => {
        // todo: map this properly
        let fonts = {};
        if (this.state.webinars.length) {
            for (let i in this.state.webinars) {
                if (this.state.webinars[i].button.buttonDetails.fonts) {
                    fonts = mergeFonts(fonts, this.state.webinars[i].button.buttonDetails.fonts);
                }
            } // for
        }
        return fonts;
    };

    prepForSave = () => {
        if (this.state.editMode) {
            this.toggleEditModes(() => {
                this.save();
            });
        }
        else {
            this.save();
        }
    };

    save = () => {
        let isError = false;
        let systemMsg = [];

        if (this.state.editMode) {
            this.toggleEditModes();
        }

        if (jQuery.trim(jQuery("<div>" + this.state.title + "</div>").text()) === '') {
            isError = true;
            systemMsg.push('A title is required.');
        }

        let re = new RegExp(/^[a-z][a-z0-9-_]+$/i);
        if ($.trim(this.state.uniqueName) === '') {
            isError = true;
            systemMsg.push('You must enter the page name for this page and it must be unique.');
        }
        else if (!re.test(this.state.uniqueName)) {
            isError = true;
            systemMsg.push('Your page name must not contain special characters except - and _');
        }

        let webinars = [];
        let newItems;
        if (this.webinarList) {
            webinars = this.webinarList.getItems();
            webinars = this.sort(webinars);
            newItems = webinars.filter(function (item, i) {
                // remove any items that have no values
                return !(item.videoCode === '');
            });
        }

        if (newItems.length === 0) {
            isError = true;
            systemMsg.push('A webinar is required.');
        }
        if (isError) {
            bootbox.alert(systemMsg.join('<br/>'));
        }
        else {
            let socialLinks = [];
            if (this.socialList) {
                socialLinks = this.socialList.getItems();
            }


            let testimonials = [];
            if (this.testimonialList) {
                testimonials = this.testimonialList.getItems();
            }

            let body = jQuery('#content').clone();
            jQuery('.edit-mode-bar', body).closest('.row').remove();
            body = jQuery(body).html();

            let params = {
                id: this.state.webinarId,
                title: this.state.title,
                uniqueName: this.state.uniqueName,
                testimonialList: testimonials,
                webinarList: webinars,
                socialList: socialLinks,
                style: this.state.style,
                body: body,
                fonts: this.getFonts()
            };

            // add custom content fields
            params.contents = {};
            if (this.customBlocks) {
                for (let i in this.customBlocks) {
                    params.contents[i] = {
                        draftContent: this.customBlocks[i].getRaw(),
                        html: this.customBlocks[i].getHtml()
                    };
                }
            }

            // todo: replace jQuery.ajax with fetch
            jQuery.ajax({
                dataType: "json",
                async: false,
                /*cache: false,*/
                url: this.apiUrl,
                type: "POST",
                contentType: 'application/json',
                xhrFields: {
                    withCredentials: true
                },
                data: JSON.stringify({method: 'save', params: params}),
                success: (data) => {
                    if (typeof data.result !== 'undefined') {
                        if (data.result) {
                            bootbox.alert('Webinar Saved Successfully.');
                            if (!this.state.webinarId) {
                                this.setState({webinarId: data.result});
                                if (history && history.pushState) {
                                    history.pushState({}, this.state.title, '?id=' + data.result);
                                    this.load(data.result);
                                }
                            }
                        }
                        else {
                            tf.rpcError({error: 'Unable to save.'});
                        }
                    }
                    else if (data.error) {
                        tf.rpcError(data);
                    }
                    else {
                        // unknown
                    }
                }

            });
        }

    };

    load = (id) => {
        // todo: replace jQuery.ajax with fetch
        jQuery.ajax({
            dataType: "json",
            async: false,
            //cache: false, If set to false, it will force requested pages not to be cached by the browser.
            // Note: Setting cache to false will only work correctly with HEAD and GET requests. It works by appending "_={timestamp}" to the GET parameters.
            // The parameter is not needed for other types of requests, except in IE8 when a POST is made to a URL that has already been requested by a GET.
            url: this.apiUrl,
            type: "POST",
            contentType: 'application/json',
            /*xhrFields: {
                withCredentials: false
            },*/
            data: JSON.stringify({method: 'get', params: {id: id}}),
            success: (data) => {
                if (typeof data.result !== 'undefined') {
                    this.setState({
                        webinarId: data.result.id,
                        title: data.result.title,
                        uniqueName: data.result.uniqueName,
                        socialLinks: data.result.socialList,
                        webinars: data.result.webinarList,
                        testimonials: data.result.testimonialList,
                        contents: data.result.contents,
                        style: data.result.style ? data.result.style : this.state.style,
                    }, () => {
                        // push content to blocks
                        for (let i in this.state.contents) {
                            if (this.customBlocks[i]) {
                                this.customBlocks[i].setEditorState(this.state.contents[i].draftContent);
                            }
                        }

                        if (data.result.fonts && data.result.fonts.GoogleFonts && data.result.fonts.GoogleFonts.length) {
                            WebFont.load({
                                google: {
                                    families: data.result.fonts.GoogleFonts
                                },
                                classes: false,
                                events: false
                            });
                        }
                        this.forceUpdate();
                    });
                }
                else if (data.error) {
                    tf.rpcError(data);
                }
                else {
                    // unknown
                }
            }
        });
    };

    sort = (wList) => {

        let newList = wList.sort(function (a, b) {
            let isBigger = -1;
            let hourA = a.hour * 1;
            let hourB = b.hour * 1;

            if (a.meridiem === 'pm') {
                if (hourA !== 12) {
                    hourA += 12;
                }
            }
            else {
                if (hourA === 12) {
                    hourA = 0;
                }
            }

            if (b.meridiem === 'pm') {
                if (hourB !== 12) {
                    hourB += 12;
                }
            }
            else {
                if (hourB === 12) {
                    hourB = 0;
                }
            }

            let videoStartA = moment().set({hour: hourA, minute: a.minute, second: 0}).unix();
            let videoStartB = moment().set({hour: hourB, minute: b.minute, second: 0}).unix();
            if (videoStartA > videoStartB) {
                isBigger = 1;
            }

            return isBigger;
        });

        return newList;
    };

    onWebinarChange = (webinar) => {
        this.setState({playingWebinar: webinar});
    };

    componentWillMount = () => {
        let id = null;
        if (this.props.id) {
            id = this.props.id;
            id = id.toString();
        }
        else {
            id = tf.gup('id');
        }
        if (id) {
            this.load(id);
        }

    };

    customBlockMount = (component) => {
        this.customBlocks[component.props.name] = component;
    };

    updateTestimonials = (testimonials) => {
        this.setState({
            testimonials: testimonials
        })
    };

    handleTitleChange = (event) => {
        this.setState({
            title: event.target.value
        })
    };

    handleRandomChange = (event) => {
        this.setState({
            random: event.target.value
        })
    };


    handleSimpleChange = (event, callback) => {
        let fieldDetails = {};
        fieldDetails[event.target.name] = event.target.value;
        this.setState(fieldDetails, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    };

    handleBackgroundColor = (event) => {
        let style = Object.assign([], this.state.style);
        if (style.length === 0) {
            style.push({main: event})
        }
        else {
            style[0].main = event;
        }
        this.setState({
            style: style
        })
    };

    render() {

        let mainColor = '#0054a6';
        let navbar = null;
        if (this.state.style.length > 0) {
            if (this.state.style[0] !== null) {
                mainColor = this.state.style[0].main;
            }
        }
        if (this.props.live === false) {
            let editButtonLabel = this.state.editMode ? (
                <span><i className="fa fa-eye" aria-hidden="true"/> Preview</span>) : (
                <span><i className="fa fa-pencil" aria-hidden="true"/> Edit</span>);

            navbar = (<div className="row">
                <div className="col-md-12">
                    <div className="edit-mode-bar">
                        <a className="btn btn-primary" onClick={this.toggleEditModes}
                           style={{marginRight: 10, marginLeft: 10}}>{editButtonLabel}</a>
                        <div className="input-group">
                            <input name="uniqueName" value={this.state.uniqueName} className="pull-right form-control"
                                   style={{maxWidth: '15em'}} onChange={this.handleSimpleChange}
                                   placeholder="Choose a Unique Name"/>
                            <span className="input-group-btn">
							<a className="btn btn-primary head pull-right" onClick={this.prepForSave}><i
                                className="fa fa-cloud" aria-hidden="true"/> Save Webinar</a>
							</span>
                        </div>
                    </div>

                </div>
            </div>);
        }
        // console.log(this.state.editMode);

        // This renders the entire application
        return (
            <div id="content">
                {/* <div id="contact-us" style={{position: 'fixed', bottom: 0, zIndex: '1'}}>
					<ContactUsRequest editMode={this.state.editMode} webinarId={this.state.webinarId}/>
				</div> */}
                <div className="navbar navbar-default">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                {
                                    this.state.editMode ?
                                        <ContentEditable style={null} tagName="h1" className="page-title"
                                                         html={this.state.title} disabled={false}
                                                         onChange={this.handleTitleChange}/> :
                                        <ContentEditable tagName="h1" className="page-title" html={this.state.title}
                                                         disabled={true} onChange={this.handleTitleChange}/>
                                }

                            </div>
                        </div>
                        {navbar}
                    </div>
                </div>

                <div id="main" className="" style={{backgroundColor: mainColor}}>
                    <div style={{position: 'relative', height: 30}}>
                        <BackgroundButton editMode={this.state.editMode} onChange={this.handleBackgroundColor}/>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-8" style={{paddingLeft: 15, paddingRight: 15}}>
                                <div className="video-part panel panel-default">
                                    <VideoPanel items={this.state.webinars} onPlay={this.onWebinarChange}
                                                path={this.props.path}/>
                                    <CustomBlock
                                        name="customTwo"
                                        onMount={this.customBlockMount}
                                        className="instruction"
                                        editMode={this.state.editMode}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <WebinarList ref={(list) => {
                                    this.webinarList = list;
                                }}
                                             items={this.state.webinars}
                                             editMode={this.state.editMode}
                                             playingWebinar={this.state.playingWebinar}
                                             customBlockMount={this.customBlockMount}/>
                                <SocialList ref={(list) => {
                                    this.socialList = list;
                                }}
                                            items={this.state.socialLinks}
                                            editMode={this.state.editMode}/>
                                <CustomBlock
                                    name="customOne"
                                    onMount={this.customBlockMount}
                                    className="well"
                                    editMode={this.state.editMode}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="testimonials">
                    <div className="container">
                        <TestimonialList
                            ref={(ref) => {
                                this.testimonialList = ref
                            }}
                            items={this.state.testimonials}
                            updateUserTestimonials={this.updateTestimonials}
                            editMode={this.state.editMode}/>
                    </div>
                </div>

                <div id="footer">
                    <p>&copy; 2022 The Conversion Pros.
                        Copyright All Rights Reserved. </p>
                </div>
            </div>
        )
    }
} // Webinar


export {Webinar};
