import React from "react";
import 'rc-slider/assets/index.css';
import {CustomButtonEditor} from "./CustomButtonEditor";

const jQuery = window.jQuery, bootbox = window.bootbox;

class CustomButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            buttonDetails: {
                label: 'Continue',
                url: '',
                newTab: true,
                size: 0,
                countSeconds: 0,
                className: 'btn btn-default',
                fonts: {},
                style: {
                    cornerRadius: 0,
                    backgroundColor: '#999999',
                    color: '#FFFFFF',
                    fontFamily: ''
                }
            }
        };
    } // constructor()

    onChange = () => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.state);
        }
    };

    openEditor = () => {
        this.editor.open();
    };

    onConfirm = (buttonDetails) => {
        //console.log(buttonDetails);
        this.setState({buttonDetails: buttonDetails}, () => {
            this.forceUpdate();
            this.onChange();
        });
    }; // onChange()

    componentWillMount = () => {
        if (this.props.details) {
            this.setState(this.props.details);
        }
    };

    render() {
        // todo:
        let includeButton = (
            <div className="form-group">
                <label><input type="checkbox" checked={this.state.active} onChange={(event) => {
                    this.setState({active: !this.state.active}, (event) => {
                        this.props.onChange(this.state);
                    })
                }}/> Include redirect button?</label>
            </div>
        );

        if (!this.state.active && this.props.editMode) {
            return includeButton;
        }
        let button = null;

        let style = {};
        jQuery.extend(style, this.state.buttonDetails.style);

        if (this.props.live === true) {
            // live mode
            return (
                <a href={this.state.buttonDetails.url} className={this.state.buttonDetails.className}
                   onClick={(event) => {
                       if (typeof this.props.onClick === 'function') {
                           this.props.onClick(event);
                       }
                   }} target={this.state.buttonDetails.newTab ? '_blank' : '_self'} style={style} ref={(ref) => {
                    this.button = ref
                }}>{this.state.buttonDetails.label}</a>
            );
        }
        else if (this.props.editMode) {
            // edit mode
            button = (<div className="preview well">
                <small className="pull-right">preview</small>
                <a href={this.state.buttonDetails.url} className={this.state.buttonDetails.className}
                   onClick={(event) => {
                       event.preventDefault();
                       this.openEditor();
                   }} target={this.state.buttonDetails.newTab ? '_blank' : '_self'} style={style} ref={(ref) => {
                    this.button = ref
                }}>{this.state.buttonDetails.label}</a></div>);
        }
        else {
            // preview mode
            button = (<div className="preview"><a href={this.state.buttonDetails.url}
                                                  className={this.state.buttonDetails.className} onClick={(event) => {
                event.preventDefault()
            }} target={this.state.buttonDetails.newTab ? '_blank' : '_self'} style={style} ref={(ref) => {
                this.button = ref
            }}>{this.state.buttonDetails.label}</a></div>);
        }

        return (
            <div>
                {includeButton}
                {button}
                <CustomButtonEditor ref={(editor) => {
                    this.editor = editor
                }} onConfirm={this.onConfirm} targetButton={this.button} buttonDetails={this.state.buttonDetails}/>
            </div>
        );
    } // render()

} // CustomButton

export {CustomButton};
