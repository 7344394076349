import React from "react";
import {Countdown} from "./Countdown";
import YouTube from "react-youtube";
import {RedirectButton} from "./RedirectButton";
import Vimeo from "@u-wave/react-vimeo";
import tf from "./shared/tf.jsx";
import {List} from "immutable";

const jQuery = window.jQuery, bootbox = window.bootbox, WebFont = window.WebFont, moment = window.moment;

class VideoPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            webinars: [],
            activeVideo: null,
            nextVideo: null,
            prevVideo: null,
            player: null,
            showLastVideo: false,   // indicate whether user is watching prevVideo
            showVideoFinished: true,    // indicate whether user is watching some video when time to play new video
            playingVideo: null,
        }
    } // constructor()

    componentDidMount = () => {

      //  console.log('componentDidMount',this.props)
        this.pickVideo(this.props).then(results => {
            if (results === null) return null;
            //console.log('results',results)
            this.setState({
                    nextVideo: results.nextVideo,
                    prevVideo: results.prevVideo,
                    activeVideo: results.activeVideo,
                    playingVideo: results.activeVideo
                },
                () => {
                    this.props.onPlay(this.state.playingVideo);

                    if (this.state.activeVideo === null && this.state.nextVideo !== null) {
                        let seconds = this.calculateSeconds(this.state.nextVideo);
                        if (seconds < 0) {
                            seconds += 86400;
                        }
                        this.timerHandle = setTimeout(() => {
                            let results = this.pickVideo(this.props);
                            //console.log('results', results);
                            if (results === null) return null;
                            this.setState({
                                    nextVideo: results.nextVideo,
                                    prevVideo: results.prevVideo,
                                    activeVideo: results.activeVideo,
                                    playingVideo: results.activeVideo,
                                    webinars: results.webinars,
                                }, () => {
                                    this.props.onPlay(this.state.playingVideo);
                                }
                            );
                        }, seconds * 1000);
                    }
                }
            );
        }).catch(e => {
        });


    }; // componentDidMount()

    stateChange = (prevState) => {

        // console.log(this.state, prevState)

        if ((this.state.activeVideo && prevState.activeVideo) || (!this.state.activeVideo && prevState.activeVideo)) {
            return true;
        } else if (this.state.activeVideo === prevState.activeVideo && prevState.activeVideo && this.state.activeVideo) {
            console.log(prevState.activeVideo, this.state.activeVideo)
            console.log(typeof prevState.activeVideo, typeof this.state.activeVideo)
            if (prevState.activeVideo.videoCode !== this.state.activeVideo.videoCode) {
                return true
            }
        }
        if ((this.state.nextVideo && prevState.nextVideo) || (!this.state.nextVideo && prevState.nextVideo)) {
            return true;
        } else if (this.state.nextVideo === prevState.nextVideo && prevState.nextVideo && this.state.nextVideo) {
            console.log(prevState.nextVideo, this.state.nextVideo)
            console.log(typeof prevState.nextVideo, typeof this.state.nextVideo)
            if (prevState.nextVideo.videoCode !== this.state.nextVideo.videoCode) {
                return true
            }
        }
        if ((this.state.prevVideo && prevState.prevVideo) || (!this.state.prevVideo && prevState.prevVideo)) {
            return true;
        } else if (this.state.prevVideo === prevState.prevVideo && prevState.prevVideo && this.state.prevVideo) {
            console.log(prevState.prevVideo, this.state.prevVideo)
            console.log(typeof prevState.prevVideo, typeof this.state.prevVideo)
            if (prevState.prevVideo.videoCode !== this.state.prevVideo.videoCode) {
                return true
            }
        }

        // if ((this.state.nextVideo === null && prevState.nextVideo !== null) ||  (this.state.nextVideo !== null && prevState.nextVideo === null)) {
        //     return true;
        // } else if (this.state.nextVideo === prevState.nextVideo && prevState.nextVideo !== undefined && this.state.nextVideo !== undefined){
        //     console.log(prevState.nextVideo, this.state.nextVideo)
        //     if (prevState.nextVideo.videoCode !== this.state.nextVideo.videoCode) {
        //         return true
        //     }
        // }

        // if((this.state.prevVideo === null && prevState.prevVideo !== null) ||  (this.state.prevVideo !== null && prevState.prevVideo === null)) {
        //     return true;
        // } else if (this.state.prevVideo === prevState.prevVideo && prevState.prevVideo !== undefined && this.state.prevVideo !== undefined){
        //     console.log(prevState.prevVideo, this.state.prevVideo)
        //     if (prevState.prevVideo.videoCode !== this.state.prevVideo.videoCode) {
        //         return true
        //     }
        // }

        return false
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('componentDidUpdate', prevProps, prevState)
        //only the video changed
        // if ((this.state.activeVideo !== prevState.activeVideo) || (this.state.prevVideo !== prevState.prevVideo) || (this.state.nextVideo !== prevState.nextVideo)) {
        //console.log(prevState)
        let stateChanged = this.stateChange(prevState);

        if (stateChanged) {
            // if(this.state.activeVideo.youtubeCode !== prevState.activeVideo.youtubeCode || this.state.nextVideo.youtubeCode !== prevState.nextVideo.youtubeCode || this.state.prevVideo.youtubeCode !== prevState.prevVideo.youtubeCode) {
            // keep there is no other time out when set new time out
            clearTimeout(this.timerHandle);
            if (this.state.nextVideo) {
                let seconds = this.calculateSeconds(this.state.nextVideo);
                // if time passed at today, then add one day
                if (seconds < 0) {
                    seconds += 86400;
                }

                // after seconds pick up new video
                this.timerHandle = setTimeout(() => {
                    if (!this.state.showVideoFinished) {
                        // the video user watching hasn't finished, ask user whether they want to watch next video
                        bootbox.confirm({
                            message: "A new webinar has begun. Do you want to watch it?",
                            buttons: {
                                confirm: {
                                    label: 'Yes',
                                    className: 'btn-success'
                                },
                                cancel: {
                                    label: 'No',
                                    className: 'btn-danger'
                                }
                            },
                            callback: (result) => {
                                if (result === true) {
                                    this.setState({showVideoFinished: true});
                                    this.pickVideo(this.props).then(results => {
                                        if (results === null) return null;
                                        // while waiting for user the next video became the active one
                                        this.setState({
                                            nextVideo: results.nextVideo,
                                            prevVideo: results.prevVideo,
                                            activeVideo: results.activeVideo,
                                            playingVideo: results.activeVideo,
                                            webinars: results.webinars,
                                            showLastVideo: false
                                        }, () => {
                                            this.props.onPlay(this.state.playingVideo);
                                        });
                                        // }
                                        clearInterval(this.timerHandle);
                                        if (!results.activeVideo) {
                                            bootbox.alert('You have missed the new webinar, please waiting for the next one!');
                                        }
                                    })
                                }
                                else {
                                    // user chose No
                                }
                            }
                        });
                    }
                    else {
                        // no video is playing right now
                        this.pickVideo(this.props).then(results => {
                            if (results === null) return null;
                            this.setState({
                                    nextVideo: results.nextVideo,
                                    prevVideo: results.prevVideo,
                                    activeVideo: results.activeVideo,
                                    playingVideo: results.activeVideo,
                                    webinars: results.webinars,
                                }, () => {
                                    this.props.onPlay(this.state.playingVideo);
                                }
                            );
                        })
                    }

                }, seconds * 1000);
            }
        }
        // }

    }; // componentDidUpdate()


    componentWillReceiveProps = (nextProps) => {
        // console.log('componentWillReceiveProps',nextProps)
        // only the items changed, pick up video
        if (JSON.stringify(this.props.items) !== JSON.stringify(nextProps.items)) {
            //  console.log('componentWillReceiveProps confirm',nextProps)
            this.pickVideo(nextProps).then(results => {
                if (results === null) {
                    this.setState({
                            nextVideo: null,
                            prevVideo: null,
                            activeVideo: null,
                            playingVideo: null,
                            showVideoFinished: true,
                            showLastVideo: false
                        }, () => {
                            this.props.onPlay(null);
                        }
                    );
                }
                else {
                    this.setState({
                            nextVideo: results.nextVideo,
                            prevVideo: results.prevVideo,
                            activeVideo: results.activeVideo,
                            playingVideo: results.activeVideo,
                            showVideoFinished: true,
                            showLastVideo: false
                        }, () => {
                            this.props.onPlay(this.state.playingVideo);
                        }
                    );
                }
            })
        }
    }; // componentWillReceiveProps()


    calculateSeconds = (nextVideo) => {
        // console.log('calculateSeconds',nextVideo)
        let hour = nextVideo.hour * 1;
        if (nextVideo.meridiem === 'pm') {
            if (hour !== 12) {
                hour += 12;
            }
        }
        else {
            if (hour === 12) {
                hour = 0;
            }
        }
        let now = moment().unix();
        let videoStart = moment().set({hour: hour, minute: nextVideo.minute, second: 0}).unix();

        return videoStart - now;
    }; // calculateSeconds()

    onReady = (event) => {
        //console.log('onReady',event)
        // when video begin to show, set ShowVideoFinished to false, then we will ask user whether they want to watch new video
        // console.log('inside onReady function', event)
        this.setState({
            player: event.target,
            showVideoFinished: false,
        });
    }; // onReady()

    onEnd = (event) => {
        // console.log('onEnd', event)
        // when video finished, pick up new video again and set ShowVideoFinished to true, then we won't ask user whether they want to watch new video
        // console.log('onEnd', event)
        // when video finished, pick up new video again and set ShowVideoFinished to true, then we won't ask user whether they want to watch new video
        this.pickVideo(this.props).then(results => {
            if (results === null) return null;
            // console.log('results', results);
            if (results.activeVideo) {
                //if users finished the video ahead of time, don't let them watch again
                this.setState({
                        nextVideo: results.nextVideo,
                        prevVideo: results.activeVideo,
                        activeVideo: null,
                        playingVideo: null,
                        showVideoFinished: true,
                        webinars: results.webinars,
                        showLastVideo: false
                    }, () => {
                        this.props.onPlay(this.state.playingVideo);
                    }
                );
            }
            else {
                //if users didn't finish the video ahead of time
                this.setState({
                        nextVideo: results.nextVideo,
                        prevVideo: results.prevVideo,
                        activeVideo: results.activeVideo,
                        playingVideo: results.activeVideo,
                        showVideoFinished: true,
                        showLastVideo: false,
                        webinars: results.webinars
                    }, () => {
                        this.props.onPlay(this.state.playingVideo);
                    }
                );
            }
        }).catch(e => {
        });
    }; // onEnd()

    showPrevVideo = () => {
        // console.log('showPrevVideo')
        //Press the show last webinar button, keep this prevVideo's URL to make sure when prevVideo change users still can watch that video
        this.setState({
            showLastVideo: true,
            playingVideo: this.state.prevVideo,
        })
    }; // showPrevVideo()

    orderVideos(items) {
        let newItems = [];
        let firstIndex = null;

        for (let i in items) {
            let timeUntilVideo = this.calculateSeconds(items[i]);
            items[i].startTime = 0;
            if (timeUntilVideo >= 0) {
                items[i].timeUntilVideo = timeUntilVideo;

                if (firstIndex === null) {
                    if (i > 0 && items[i - 1].videoLength + items[i - 1].timeUntilVideo > 0) {
                        // last video is not finished running yet
                        firstIndex = i - 1;
                        items[i - 1].startTime = -1 * items[i - 1].timeUntilVideo;
                    }
                    else {
                        // previous video is over
                        firstIndex = i;
                    }
                }
            }
            else {
                items[i].timeUntilVideo = timeUntilVideo;
            }
        } // for

        if (firstIndex !== null) {
            for (let i = firstIndex; i < items.length; i++) {
                newItems.push(items[i]);
            }
            for (let i = 0; i < firstIndex; i++) {
                newItems.push(items[i]);
            }
        }
        else if (items.length > 0 && items[items.length - 1].timeUntilVideo < 0 && -1 * items[items.length - 1].timeUntilVideo < items[items.length - 1].videoLength) {
            items[items.length - 1].startTime = -1 * items[items.length - 1].timeUntilVideo;
            newItems = [items.pop()];
            newItems = newItems.concat(items);
        }
        else {
            newItems = items;
        }

        if (newItems.length > 0) { // newItems has to be set
            if (newItems[0].startTime > 0 && newItems[0].timeUntilVideo < 0) {
                // need to start part way through
            }
            else if (newItems[0].timeUntilVideo < 0) {
                newItems[0].timeUntilVideo += 86400;
            }
        }

        //this.setState({webinars: newItems});
        // console.log('orderVideos', newItems);
        return newItems;
    }

    pickVideo = async (nextProps) => {
        //console.log('pickVideo', nextProps)
        // To start a video at a certain timestamp(this will only happen when a view logs in when the video has already started)
        let activeVideo = null;
        let prevVideo = null;
        let nextVideo = null;

        let items = this.orderVideos(Object.assign([], nextProps.items));
        let length = items.length;

        if (!items || items.length === 0) {
            return null;
        }

        if(items[0].timeUntilVideo < 0){
            // start playing part way through
            activeVideo = items[0];
            nextVideo   = (length > 1) ? items[1] : items[0];
            prevVideo   = items[length - 1];
        }
        else if(items[0].timeUntilVideo > 0 && items[0].timeUntilVideo < 86398){
            // need to wait until next video start
            nextVideo = items[0];
            prevVideo = items[length - 1];
        }
        else{
            // start video at the begining
            activeVideo = items[0];
            nextVideo   = (length > 1) ? items[1] : items[0];
            prevVideo   = items[length - 1];
        }

        // console.log({ nextVideo: nextVideo, prevVideo: prevVideo, activeVideo: activeVideo, webinars: items})
        return {nextVideo: nextVideo, prevVideo: prevVideo, activeVideo: activeVideo, webinars: items};
    }; // pickVideo()

    render() {
        let startTime = 0;
        let youtubecontrol = 1;

        if (this.state.playingVideo && !this.state.showLastVideo) {
            // when users view activeVideo, they will watch them from the time they enter
            startTime = this.state.playingVideo.startTime;
            if (this.state.playingVideo.youtubeControl === false) {
                youtubecontrol = 0;
            }
        }
        else {
            // when users show prevVideo, they will watch it from beginning
        }
        let opts = {
            playerVars: {
                autoplay: 1,
                start: startTime,
                controls: youtubecontrol,
                rel: 0,
                modestbranding: 0,
                showinfo: 0,
                enablejsapi: 1
            }
        };

        let lastWebinarButton = null;
        let placeholderImage = this.props.path + '/assets/images/shutterstock_1181261884_1080.jpg';

        // todo: make show allow a user uploaded image
        let show = (<img className="img-responsive center-block" src={placeholderImage} alt="Coming Soon"/>);

        if (this.state.playingVideo) {
            // display video
            show = (
                <div className="embed-responsive embed-responsive-16by9">
                    {this.state.playingVideo.videoType === "YouTube" ?
                        <YouTube videoId={this.state.playingVideo.videoCode} onReady={this.onReady} onEnd={this.onEnd}
                                 opts={opts} onStateChange={this.onEvent}/>
                        :
                        <Vimeo video={this.state.playingVideo.videoCode} onReady={this.onReady} onEnd={this.onEnd}
                               autoplay start={startTime}/>
                        // <Vimeo videoId={this.state.playingVideo.vimeoCode} onReady={this.onReady} onEnded={this.onEnd} autoplay={true}/>
                    }
                </div>
            );
        }
        else {
            // not playing video
        }

        if (this.state.prevVideo && !this.state.playingVideo) {
            // no video is playing so show the play last video button
            lastWebinarButton = (
                <div style={{textAlign: 'center'}}>
                    <button onClick={this.showPrevVideo} className="btn btn-primary"><i
                        className="fa fa-video-camera"/> Show Last Webinar
                    </button>
                </div>
            );
        }

        return (
            <div>
                <div className="count-down text-center panel-heading">
                    <h4><Countdown activeVideo={this.state.activeVideo} nextVideo={this.state.nextVideo}
                                   showLastVideo={this.state.showLastVideo}
                                   videoFinished={this.state.showVideoFinished}/></h4>
                </div>
                <div>
                    {lastWebinarButton}
                    {show}
                </div>
                <RedirectButton playingVideo={this.state.playingVideo}/>
            </div>
        )
    }
} // VideoPanel

export {VideoPanel}