import React from "react";
import {CustomButton} from './CustomButton.jsx';
import tf from './shared/tf.jsx';
import BasicOverlay from './shared/BasicOverlay';

const moment = window.moment;

function extractYoutubeVideoId(url) {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    let match = url.match(regExp);
    if (match && match[7].length === 11) {
        return match[7];
    } else {
        return url.replace(/\s/g, '');
    }
}

function extractVimeoVideoId(url) {
    let regExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[0-9]+)?/;
    let match = url.match(regExp);
    if (match && match[match.length - 1].length === 9) {
        return match[match.length - 1];
    } else {
        return url.replace(/\s/g, '');
    }
}

class WeekCalendar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal,
            videoCode: '',
            videoLength: null,
            hour: '',
            meridiem: '',
            videoType: 'YouTube',
            minute: '',
            timeZone: moment().utcOffset(),
            key: 0,
            id: 0,
            youtubeControl: true,
            button: {
                active: false,
                buttonDetails: {
                    show: false,
                    label: 'Continue',
                    url: '',
                    newTab: true,
                    size: 0,
                    className: 'btn btn-default',
                    style: {
                        borderRadius: 0,
                        backgroundColor: '#999999',
                        color: '#FFFFFF',
                        fontFamily: ''
                    }
                }
            }
        };
    } // constructor()

    getYoutubeVideoDetails = youtubeCode => {
        if(youtubeCode === '') return 0;
        tf.rpc({method: 'getYoutubeVideoDetails', params: {code: youtubeCode}}).promise.then(data => {
            console.log(data)

            let duration = data.result.duration
            return duration;
        }).catch(error => {
            console.log('error', error)
            return error;
        });
        /*return new Promise((resolve, reject) => {
            tf.rpc({method: 'getYoutubeVideoDetails', params: {code: youtubeCode}}).then(data => {
                console.log(data)

                let duration = data.result.duration
                resolve(duration);
            }).catch(error => {
                console.log('error', error)
                reject(error);
            });
        })*/
    }; // getVideoDetails()

    handleYouTubeVideoCode = async (e) => {
        console.log('handleYouTubeVideoCode', e.target.value);
        let youtubeCode = extractYoutubeVideoId(e.target.value);


        let videoLength = await this.getYoutubeVideoDetails(youtubeCode);
        // let videoLength = await this.getYoutubeStreamDetails(youtubeCode);

        console.log(videoLength)
        this.setState({
            videoCode:      youtubeCode,
            videoLength:    videoLength,
            youtubeControl: true,
        }, () => {
            this.props.onChange(this.state);
        });


    }; // handleYouTubeVideoCode()

    getVimeoVideoDetails = (vimeoCode) => {

        /*return new Promise((resolve, reject) => {
            tf.rpc({method: 'getVimeoVideoDetails', params: {code: vimeoCode}}).then(data => {
                console.log(data);
                let duration = data.result.duration
                resolve(duration)
            }).catch(error => {
                // console.log('error', error)
                reject(error)
            });
        })*/


        if(vimeoCode === '') return 0;
        tf.rpc({method: 'getVimeoVideoDetails', params: {code: vimeoCode}}).promise.then(data => {
            console.log(data)

            let duration = data.result.duration
            return duration;
        }).catch(error => {
            console.log('error', error)
            return error;
        });



    }; // getVimeoVideoDetails()

    handleVimeoVideoCode = async (e) => {
        let vimeoCode = extractVimeoVideoId(e.target.value);

        let videoLength = await this.getVimeoVideoDetails(vimeoCode);
        this.setState({
            videoCode: vimeoCode,
            videoLength: videoLength,
            isStream: false,
            youtubeControl: false
        }, () => {
            this.props.onChange(this.state);
        });


    }; // handleVimeoVideoCode()

    handleUpdateHours = (e) => {
        let hour = 0;
        if (e.target.value < 1) {
            hour = 1;
        } else if (e.target.value > 12) {
            hour = 12
        } else {
            hour = e.target.value
        }
        this.setState({
            hour: hour
        }, () => {
            this.props.onChange(this.state);
        });

    };

    handleUpdateMin = (e) => {
        let min = 0;
        if (e.target.value < 0) {
            min = 0;
        } else if (e.target.value > 59) {
            min = 59
        } else {
            min = e.target.value
        }
        this.setState({
            minute: min
        }, () => {
            this.props.onChange(this.state);
        });
    };

    handleUpdateTimeZone = (timezone) => {
        this.setState({
                timeZone: timezone
            }, () => {
                this.props.onChange(this.state);
            }
        );
    };

    componentWillMount = () => {
        if (this.props.defaults) {
            this.setState(this.props.defaults);
        }
    };

    setMeridiem = (meridiem) => {
        this.setState({
                meridiem: meridiem
            }, () => {
                this.props.onChange(this.state);
            }
        );
    };

    setVideoType = (type) => {
        this.setState({
                videoType: type,
                videoCode: ''
            }, () => {
                this.props.onChange(this.state);
            }
        );
    };

    handleButtonChange = (newState) => {
        //console.log(newState);
        this.setState({
                button: newState
            }, () => {
                this.props.onChange(this.state);
            }
        );
    };

    render() {
        let content = null;
        let addButton = null;
        if (this.props.isLastItem) {

            addButton = (
                <button onClick={() => {
                    this.props.onAdd()
                    this.props.onClose();
                }} className="btn btn-primary"><i className="fa fa-plus"/> Add
                </button>
            );
        }
        let meridiemAMStyle = {cursor: 'pointer'}, meridiemPMStyle = {cursor: 'pointer'};
        if (this.state.meridiem === 'am') {
            meridiemAMStyle = {backgroundColor: '#337ab7', color: '#FFFFFF'};
        } else {
            meridiemPMStyle = {backgroundColor: '#337ab7', color: '#FFFFFF'};
        }

        let youtubeStyle = {cursor: 'pointer'}, vimeoStyle = {cursor: 'pointer'};
        if (this.state.videoType === 'YouTube') {
            youtubeStyle = {backgroundColor: '#337ab7', color: '#FFFFFF'};
        } else {
            vimeoStyle = {backgroundColor: '#337ab7', color: '#FFFFFF'};
        }

        content = (
            <div className="well well-sm">
                <div className="form-group">
                    <h3>Video Code </h3>
                    <div className="form-group">
        
                        <span className="input-group-addon" style={youtubeStyle}
                              onClick={() => this.setVideoType('YouTube')}>
                            YouTube
                        </span>
                        <span className="input-group-addon" style={vimeoStyle}
                              onClick={() => this.setVideoType('Vimeo')}>
                            Vimeo
                        </span>


                        <label className="control-label">{this.state.videoType} Code</label>
                        {(this.state.videoType === 'YouTube') ?
                            <input placeholder="Enter a youtube video code" type="text" className="form-control"
                                   value={this.state.videoCode} id="youtubeCode"
                                   onChange={this.handleYouTubeVideoCode}/>
                            :
                            <input placeholder="Enter a vimeo video code" type="text" className="form-control"
                                   value={this.state.videoCode} id="vimeoCode"
                                   onChange={this.handleVimeoVideoCode}/>
                        }
                    </div>
                    <div className="form-group">
                        <label className="control-label">When to Start</label>
                        <div className="input-group">
                            <input type="number" min="1" max="12" value={this.state.hour} className="form-control"
                                   onChange={this.handleUpdateHours}/>
                            <span className="input-group-addon">:</span>
                            <input type="number" min="0" max="59" value={this.state.minute} className="form-control"
                                   onChange={this.handleUpdateMin}/>
                            <span className="input-group-addon" style={meridiemAMStyle}
                                  onClick={() => this.setMeridiem('am')}>
                                am
                            </span>
                            <span className="input-group-addon" style={meridiemPMStyle}
                                  onClick={() => this.setMeridiem('pm')}>
                                pm
                            </span>
                        </div>
                    </div>
                    <CustomButton name="redirect" onChange={this.handleButtonChange} editMode={this.props.editMode}
                                  details={this.state.button}/>
                    <div className="clearfix"/>
                </div>

                <div className="form-group">
                    <button onClick={() => {
                        this.props.onRemove(this.state)
                    }} className="btn btn-warning" style={{marginRight: 10}}><i className="fa fa-remove"/> Remove
                    </button>
                    {addButton}
                </div>
                <div className="clearfix"/>
            </div>
        );

        return (
            <BasicOverlay showModal={this.state.showModal} onToggle={this.onToggle}>
                {content}
            </BasicOverlay>
        )
    }

} // WebinarListItem

export default WeekCalendar;